import { ArticleModel } from 'api/article/types'
import routePath from 'app/routePath'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { ListType, MenuModel, SpecialMenuType } from './types'

const back2Top = () => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
}

/**
 * 将扁平的菜单数据，转换为带层级关系的菜单
 * @description 只考虑最多2层菜单关系
 * @param data 
 */
export const mergeMenus = (data: MenuModel[]): MenuModel[] => {
    return data.reduce<MenuModel[]>((r, m) => {
        if (!m.parent) return [...r, m]

        const parentId = m.parent as string
        const parent = r.find(p => p._id === parentId)
        if (parent) {
            parent.children = !!parent.children
                ? [...parent.children, m] :
                [m]
        }

        return r
    }, [])
}

/**
 * 将菜单带上首页
 * @param data 
 */
export const withHome = (data: MenuModel[]): MenuModel[] => [
    {
        _id: 'home',
        _createTime: 1,
        _updateTime: 1,
        title: '首页',
        code: '',
        show_detail: false,
        list_type: ListType.news,
        sort: 100000,
    },
    ...data
]

/**
 * 详情页路由
 * @description 用于详情页跳转
 * @param search 
 */
export const useArticleHistory = (search?: string) => {
    const history = useHistory()

    const route = useCallback((menuCode: string, article: ArticleModel | string, action?: 'push' | 'replace') => {
        const _action = action || 'push'

        function handleRoute() {
            back2Top()
            // 菜单路由
            history[_action](
                routePath.article.replace(':code', menuCode) +
                (search || '')
            )
        }

        if (typeof article === 'string') {
            handleRoute()
            return
        }

        // 特殊路由-首页
        if (menuCode === '') {
            history[_action](routePath.index)
            return
        }

        // 特殊路由-外链文章
        if (!!article.external_url) {
            window.open(article.external_url)
            return
        }

        handleRoute()
    }, [history, search])

    return {
        route,
        history,
    }
}

/**
 * 菜单路由
 * @description 目的用于菜单页跳转
 */
export const useMenuHistory = () => {
    const history = useHistory()

    const route = useCallback((menu: MenuModel | string, action?: 'push' | 'replace') => {
        const _action = action || 'push'

        function handleMenuRoute(code: string) {
            back2Top()
            history[_action](
                routePath.menu.replace(':code', code)
            )
        }

        if (typeof menu === 'string') {
            handleMenuRoute(menu)
            return
        }

        // 特殊路由-首页
        if (menu.code === '') {
            history[_action](routePath.index)
            return
        }

        // 特殊路由-外链
        if (!!menu.external_url) {
            window.open(menu.external_url)
            return
        }

        // 菜单路由
        handleMenuRoute(menu.code)
    }, [history])

    return {
        route,
        history,
    }
}

/**
 * 根据菜单ID查询菜单
 * @param menus 
 * @param menuId 
 */
export const findMenuById = (menus: MenuModel[], menuId: string) => {
    let menu: MenuModel | undefined

    for (let i = 0; i < menus.length; i++) {
        if (menus[i]._id === menuId) {
            menu = menus[i]
            break
        }

        if (!!menus[i].children?.length) {
            for (let j = 0; j < menus[i].children!.length; j++) {
                if (menus[i].children![j]._id === menuId) {
                    menu = menus[i].children![j]
                    break
                }
            }
            // 子菜单内找到就退出
            if (menu) break
        }
    }

    return menu
}

/**
 * 获取菜单页需要用的元数据
 * @description 同样使用于详情页
 * @param menus 具有层级关系的菜单数据
 * @param code 需要取出的菜单
 */
export const getMenuPageMetas = (menus: MenuModel[], code: string): { menu?: MenuModel, parent?: MenuModel } => {
    let menu: MenuModel | undefined

    for (let i = 0; i < menus.length; i++) {
        if (menus[i].code === code) {
            menu = menus[i]
            break
        }

        if (!!menus[i].children?.length) {
            for (let j = 0; j < menus[i].children!.length; j++) {
                if (menus[i].children![j].code === code) {
                    menu = menus[i].children![j]
                    break
                }
            }
            // 子菜单内找到就退出
            if (menu) break
        }
    }

    if (!menu) return {}

    const parent = menu.parent
        ? menus.find(p => p._id === menu?.parent) :
        undefined

    return {
        menu,
        parent,
    }
}

/**
 * 判断是否是特殊路由
 * @description 即读取小程序平台数据的路由
 * @param code 
 */
export const isSpecialMenu = (code: string) => {
    switch (code) {
        case SpecialMenuType.lecture:
            return true
        case SpecialMenuType.education:
            return true
        default:
            return false
    }
}