import HomeLayout from 'components/layouts/HomeLayout'
import Swiper from 'components/home/Swiper'
import React, { FunctionComponent } from 'react'
import Modules from 'components/home/Modules'
import Infos from 'components/home/Infos'
// import styles from './index.module.scss'

/**
 * 首页
 */
const Index: FunctionComponent = () => {
  return (
    <HomeLayout>
      <Swiper />
      <Infos />
      <Modules />
    </HomeLayout>
  )
}

export default Index