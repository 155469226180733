import React, { FunctionComponent, memo } from 'react'
import styles from './ActivityList.module.scss'
import { MenuPageProps } from '../types'
import { useRequest } from 'ahooks'
import { listActivity } from 'api/activity/query'
import { ActivityModel } from 'api/activity/types'
import { useArticleHistory } from 'api/menu/helper'
import qs from 'query-string'
import AIOImage from 'react-aio-image'
import { getTcbUrl } from 'utils/cloudbase'
import { getActivityAddress, getActivityDate } from 'api/activity/helper'

type Props = MenuPageProps

const ActivityItem = memo<{ data: ActivityModel } & Pick<Props, 'code'>>(({ code, data }) => {
  const { route } = useArticleHistory('?' + qs.stringify({ id: data.id }))

  return (
    <li className={styles.item} onClick={() => route(code, String(data.id))}>
      <AIOImage
        width='100%'
        src={getTcbUrl(data.cover)}
        className={styles.item_cover}
      />
      <h5 className={styles.item_title}>{data.title}</h5>
      <h6 className={styles.item_subtitle}>时间：{getActivityDate(data)}</h6>
      <h6 className={styles.item_subtitle}>地点：{getActivityAddress(data)}</h6>
    </li>
  )
})

/**
 * 对接小程序平台的活动列表
 */
const ActivityList: FunctionComponent<Props> = ({ code }) => {
  const { data } = useRequest(async () => await listActivity(code), {
    refreshDeps: [code]
  })
  const list = data || []

  return (
    <ul className={styles.container}>
      {
        list.map(item => (
          <ActivityItem key={item.id} data={item} code={code} />
        ))
      }
    </ul>
  )
}

export default ActivityList