import React, { Fragment, FunctionComponent } from 'react'
import styles from './ArticleDetail.module.scss'
import { ArticleModel } from 'api/article/types'
import { getArticleDate } from 'api/article/helper'
import RichContent from 'components/RichContent'

type Props = {
  data: ArticleModel
}

/**
 * 详情页-普通文章展示组件
 */
const ArticleDetail: FunctionComponent<Props> = ({ data }) => {
  return (
    <Fragment>
      <div className={styles.title__wrapper}>
        <h3 className={styles.title}>{data.title}</h3>
        <h4 className={styles.subtitle}>{getArticleDate(data)}</h4>
      </div>
      <RichContent html={data.content} />
      {
        data.extraFile && (
          <a href={data.extraFile} target='_blank' style={{ color: '#778e7c' }} download>点击下载附件</a>
        )
      }
    </Fragment>
  )
}

export default ArticleDetail