import React, { FunctionComponent } from 'react'
import styles from './Modules.module.scss'
import useHomeModules from 'api/home-module/store'
import AIOImage from 'react-aio-image'
import { getTcbUrl } from 'utils/cloudbase'
import { findMenuById, useMenuHistory } from 'api/menu/helper'
import useMenus from 'api/menu/store'
import cls from 'classnames'

const renderLoading = (isFetching: boolean) => {
  return <div className={cls(styles.loading, !isFetching && styles.loading__finished)} />
}

/**
 * 首页-路由模块
 */
const Modules: FunctionComponent = () => {
  const { data: menus } = useMenus()
  const { data } = useHomeModules()
  const { route } = useMenuHistory()

  return (
    <ul className={styles.container}>
      {
        data.map(item => {
          const menu = item.ref_menu
            ? findMenuById(menus, item.ref_menu) :
            undefined

          return (
            <li
              key={item._id}
              className={styles.item}
              onClick={() => {
                (menu && route(menu)) ||
                  (item.url && window.open(item.url))
              }}
            >
              <AIOImage
                width='100%'
                height='100%'
                src={getTcbUrl(item.cover)}
                renderPlaceholder={renderLoading}
              />
              <h3 className={styles.item_title}>{item.title}</h3>
            </li>
          )
        })
      }
    </ul>
  )
}

export default Modules