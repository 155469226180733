import { getMenuPageMetas } from 'api/menu/helper'
import useMenus from 'api/menu/store'
import BreadCrumbs from 'components/BreadCrumbs'
import React, { FunctionComponent, useMemo } from 'react'
import styles from './MenuLayout.module.scss'
import AIOImage from 'react-aio-image'
import cls from 'classnames'
import { getTcbUrl } from 'utils/cloudbase'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import appConfig from 'config/app'

type Props = {

}

const renderLoading = (isFetching: boolean) => {
  return <div className={cls(styles.loading, !isFetching && styles.loading__finished)} />
}

/**
 * 菜单页布局
 * @description 包括封面、容器边界确定、标题变化
 */
const MenuLayout: FunctionComponent<Props> = ({ children }) => {
  const code = useParams<any>()?.code || ''

  const { data: menus } = useMenus()

  const metas = useMemo(() => {
    const data = getMenuPageMetas(menus, code)
    return {
      cover: getTcbUrl(data.menu?.cover || data.parent?.cover),
      breadcrumbs:
        !data.menu
          ? [] :
          !data.parent
            ? [data.menu.title] :
            [data.parent.title, data.menu.title]
    }
  }, [menus, code])

  return (
    <div className={styles.container}>
      <AIOImage
        width='100%'
        src={metas.cover || ''}
        className={styles.cover}
        renderPlaceholder={renderLoading}
      />
      <main className={styles.content}>
        <BreadCrumbs data={metas.breadcrumbs} />
        {children}
      </main>

      <Helmet>
        <title>{appConfig.appName} | {metas.breadcrumbs[1] || '详情'}</title>
      </Helmet>
    </div>
  )
}

export default MenuLayout