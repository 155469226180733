/**
 * SPA应用的所有路由集合
 */
const routePath = {
  // >>> path
  'article': '/article/:code',
  'menu': '/menu/:code',
  'index': '/',
}

export default routePath