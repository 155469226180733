import { createBaseStore } from 'api/helper'
import { useCallback, useEffect } from 'react'
import create from 'zustand'
import { getMenus } from './query'

let _loadingState = false   // 保证不重复加载数据

const useMenuStore = create(createBaseStore('menus', getMenus))

const useMenus = () => {
    const { data, getData } = useMenuStore()

    const loadData = useCallback(
        async () => {
            if (!_loadingState && data.length === 0) {
                _loadingState = true
                await getData()
                _loadingState = false
            }
        },
        // eslint-disable-next-line
        []
    )

    useEffect(() => {
        loadData()
    }, [loadData])

    return { data }
}

export default useMenus