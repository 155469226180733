import React, { Fragment, FunctionComponent } from 'react'
import Footer from './Footer'
import Header from './Header'
import styles from './MainLayout.module.scss'

/**
 * 主布局
 * @description 处理网站整体布局
 */
const MainLayout: FunctionComponent = ({ children }) => {
  return (
    <Fragment>
      <Header />
      <main className={styles.container}>
        {children}
      </main>
      <Footer />
    </Fragment>
  )
}

export default MainLayout