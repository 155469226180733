import React, { Fragment, FunctionComponent, memo, useMemo } from 'react'
import styles from './FooterMenus.module.scss'
import cls from 'classnames'
import useMenus from 'api/menu/store'
import { useMenuHistory, withHome } from 'api/menu/helper'

/**
 * 底部导航栏-菜单栏
 */
const FooterMenus: FunctionComponent = () => {
  const { route } = useMenuHistory()

  const { data } = useMenus()
  const menus = useMemo(() => withHome(data), [data])

  return (
    <Fragment>
      {
        menus.map(m => {
          const hasChildren = m.children && m.children.length > 0

          return (
            <div key={m._id} className={styles.container}>
              <label
                className={cls(styles.parent, hasChildren && styles.parent_divider)}
                onClick={() => !hasChildren && route(m)}
              >
                {m.title}
              </label>
              <ol className={styles.children}>
                {
                  (m.children || []).map(sm => (
                    <li key={sm._id} className={styles.child} onClick={() => route(sm)}>
                      {sm.title}
                    </li>
                  ))
                }
              </ol>
            </div>
          )
        })
      }
    </Fragment>
  )
}

export default memo(FooterMenus)