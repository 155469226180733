import { createPersist } from 'api/helper'
import { getCloudBaseDB } from 'utils/cloudbase'
import { HomeModuleModel } from './types'

const DB = 'szsilk-home-module'

/**
 * 获取首页模块
 * @description 只拿4个；缓存24小时
 */
export const getHomeModules = createPersist(async (): Promise<HomeModuleModel[]> => {
    const db = await getCloudBaseDB()
    const resp = await db
        .collection(DB)
        .orderBy('sort', 'desc')
        .limit(4)
        .get()

    if (resp.data) {
        return resp.data as HomeModuleModel[]
    } else {
        return []
    }
}, 'homeModules', 24 * 60 * 60)