import appConfig from 'config/app'
import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet'
import styles from './HomeLayout.module.scss'

/**
 * 首页布局
 */
const HomeLayout: FunctionComponent = ({ children }) => {
  return (
    <section className={styles.container}>
      {children}
      <Helmet>
        <title>{appConfig.appName}</title>
      </Helmet>
    </section>
  )
}

export default HomeLayout