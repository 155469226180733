import React, { FunctionComponent, useMemo } from 'react'
import DetailBase from 'components/detail/ArticleDetail'
import { MenuPageProps } from '../types'
import { useRequest } from 'ahooks'
import { pageArticle } from 'api/article/query'

type Props = MenuPageProps

/**
 * 菜单列表-详情展示
 */
const ArticleDetail: FunctionComponent<Props> = ({ code, menuId }) => {
  const { data: pages } = useRequest(async () => await pageArticle({
    menuId,
    current: 1,
    pageSize: 1,
    showContent: true
  }), {
    refreshDeps: [menuId]
  })
  const data = useMemo(() => pages?.list[0], [pages])

  return (
    data
      ? <DetailBase data={data} /> :
      null  // FIXME: 加载中
  )
}

export default ArticleDetail