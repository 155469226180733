import classNames from 'classnames'
import React, { FunctionComponent } from 'react'
import styles from './Digital.module.scss'

type Props = {
  className?: string
}

export const DigitalButton: FunctionComponent<Props> = ({ className }) => {
  const onClick = () => window.open('http://szsilk.wglj.suzhou.com.cn/')

  return (
    <button className={classNames(styles.container, className)} onClick={onClick}>
      <div className={styles.button}>数字丝博</div>
    </button>
  )
}