import { useCallback, useEffect } from 'react'
import create from 'zustand'
import { getSwipers } from './query'
import { createBaseStore } from '../helper'
import { SwiperModel } from './types'

const useSwiperStore = create(createBaseStore<SwiperModel>('swipers', getSwipers))

const useSwipers = () => {
    const { data, getData } = useSwiperStore()

    const loadData = useCallback(
        async () => {
            if (data.length === 0) {
                await getData()
            }
        },
        // eslint-disable-next-line
        []
    )

    useEffect(() => {
        loadData()
    }, [loadData])

    return { data }
}

export default useSwipers