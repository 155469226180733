import appConfig from 'config/app'
import dayjs from 'dayjs'
import { ActivityModel } from './types'

const BASE_URL = 'https://api.szsilk.hz2.3lengjing.com/activity/app'

/**
 * 简单的请求
 * @param method 
 * @param path 
 */
export const request = async (method: 'POST' | 'GET', path: string, body?: string) => {
    try {
        const resp = await fetch(BASE_URL + path, {
            body,
            method,
            headers: {
                mode: 'cors',
                credentials: 'include',
                'Content-Type': 'application/json; charset=utf-8',
            }
        })

        if (resp.ok && resp.status >= 200 && resp.status < 400) {
            return resp.json()
        } else {
            return undefined
        }
    } catch (err) {
        return undefined
    }
}

/**
 * 获取活动日期
 * @param data 
 */
export const getActivityDate = (data: ActivityModel) => {
    const plan = data.rPlans && data.rPlans[0]
    if (!plan) return '暂无开始日期'

    const start = dayjs(plan.beginDate).format('YYYY年MM月DD日 HH:mm')
    const end = dayjs(plan.endDate).format('HH:mm')

    return start + '-' + end
}

/**
 * 获取活动地点
 * @param data 
 */
export const getActivityAddress = (data: ActivityModel) => {
    const plan = data.rPlans && data.rPlans[0]
    return !plan ? (data.address || appConfig.appName) : plan.address
}

/**
 * 名额
 * @param data 
 */
export const getActivityLimit = (data: ActivityModel) => {
    const plan = data.rPlans && data.rPlans[0]
    if (!plan) return '暂无'

    return plan.ruleApplyMax ? plan.ruleApplyMax + '人' : '不限'
}