import { useCallback, useEffect } from 'react'
import create from 'zustand'
import { getLinks } from './query'
import { createBaseStore } from '../helper'
import { LinkModel } from './types'

const useLinkStore = create(createBaseStore<LinkModel>('links', getLinks))

const useLinks = () => {
    const { data, getData } = useLinkStore()

    const loadData = useCallback(
        async () => {
            if (data.length === 0) {
                await getData()
            }
        },
        // eslint-disable-next-line
        []
    )

    useEffect(() => {
        loadData()
    }, [loadData])

    return { data }
}

export default useLinks