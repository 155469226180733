import React, { FunctionComponent } from 'react'
import styles from './MenuSwitch.module.scss'
import cls from 'classnames'

type Props = {
  visible: boolean
  onSwitch: () => void
  className?: string
}

/**
 * 菜单开关
 */
const MenuSwitch: FunctionComponent<Props> = ({ visible, onSwitch, className }) => {
  const rootStyle = cls(styles.container, visible ? styles.active : styles.inactive, className)
  return <div className={rootStyle} onClick={() => onSwitch()} />
}

export default MenuSwitch