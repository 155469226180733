import { useCallback, useEffect } from 'react'
import create from 'zustand'
import { getPlatforms } from './query'
import { createBaseStore } from '../helper'
import { PlatformModel } from './types'

let _loadingState = false   // 保证不重复加载数据

const usePlatformStore = create(createBaseStore<PlatformModel>('platforms', getPlatforms))

const usePlatforms = () => {
    const { data, getData } = usePlatformStore()

    const loadData = useCallback(
        async () => {
            if (!_loadingState && data.length === 0) {
                _loadingState = true
                await getData()
                _loadingState = false
            }
        },
        // eslint-disable-next-line
        []
    )

    useEffect(() => {
        loadData()
    }, [loadData])

    return { data }
}

export default usePlatforms