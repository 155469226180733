import { findMenuById } from 'api/menu/helper'
import useMenus from 'api/menu/store'
import usePlatforms from 'api/platform/store'
import { PlatformCode } from 'api/platform/types'
import React, { FunctionComponent, useMemo } from 'react'
import { getTcbUrl } from 'utils/cloudbase'
import styles from './Infos.module.scss'
import MenuList from './MenuList'

type Props = {

}

/**
 * 首页信息展示模块
 */
const Infos: FunctionComponent<Props> = () => {
  const { data: platforms } = usePlatforms()

  const openInfos = useMemo(() => {
    const address = platforms.find(i => i.code === PlatformCode.address)
    const openTime = platforms.find(i => i.code === PlatformCode.open_time)

    return [
      address
        ? address.title + '：' + address.txt :
        '地址：江苏苏州人民路2001号'
      ,
      openTime
        ? openTime.title + '：' + openTime.txt :
        '开放时间：每星期二至星期日9:00-17:00(16:00停止入馆)，每星期一闭馆(国家法定节假日除外)'
    ]
  }, [platforms])

  const videoUrl = useMemo(() => {
    const p = platforms.find(i => i.code === PlatformCode.home_video)
    return p?.video
  }, [platforms])

  const { data: menus } = useMenus()
  const { menuTitle, menuId, menuCode } = useMemo(() => {
    const p = platforms.find(i => i.code === PlatformCode.home_list)

    if (p && p.menu) {
      const m = findMenuById(menus, p.menu)
      return {
        menuId: m?._id,
        menuCode: m?.code,
        menuTitle: m?.title
      }
    }

    return {
      menuId: undefined,
      menuTitle: undefined
    }
  }, [platforms, menus])

  return (
    <div className={styles.container}>
      <div className={styles.openInfo}>
        <h3 className={styles.openInfo_title}>开放信息</h3>
        {
          openInfos.map((p, idx) => (
            <p key={idx} className={styles.openInfo_content}>{p}</p>
          ))
        }
      </div>
      <div className={styles.menuList}>
        {
          menuId && (
            <MenuList
              menuId={menuId}
              menuCode={menuCode!}
              menuTitle={menuTitle!}
            />
          )
        }
      </div>
      <div className={styles.videoPlayer__wrapper}>
        {
          videoUrl && (
            <video
              className={styles.videoPlayer}
              src={getTcbUrl(videoUrl)}
              loop muted autoPlay playsInline
            />
          )
        }
      </div>
    </div>
  )
}

export default Infos