import React, { CSSProperties, FunctionComponent, memo, useCallback } from 'react'
import styles from './MobileMenuItem.module.scss'
import cls from 'classnames'
import { MenuModel } from 'api/menu/types'
import imageAssets from 'assets/images'
import { useMenuHistory } from 'api/menu/helper'

type Props = {
  /** 是否被激活 */
  active: boolean
  /** 菜单数据 */
  data: MenuModel
  /** 切换激活状态 */
  onSwitch: (name: string | undefined) => void
  /** 关闭菜单栏 */
  onClose: () => void
}

const ChildItem = memo<Pick<Props, 'onClose' | 'data'>>(({ data, onClose }) => {
  const { route } = useMenuHistory()

  const onRoute = useCallback(
    () => {
      onClose()
      route(data)
    },
    // eslint-disable-next-line
    []
  )

  return (
    <li key={data._id} className={styles.child} onClick={onRoute}>
      <span className={styles.child_title}>{data.title}</span>
    </li>
  )
})

/**
 * 手机菜单单项
 */
const MobileMenuItem: FunctionComponent<Props> = ({
  data,
  active,
  onClose,
  onSwitch
}) => {
  const { route } = useMenuHistory()

  const childCount = data.children ? data.children.length : 0
  const isExpandable = childCount > 0

  const containerStyle: CSSProperties = {
    height: active ? 28 + 60 + childCount * 40 : 60
  }

  const onMenuClick = useCallback(() => {
    if (isExpandable) {
      // 展开或取消展开
      if (active) {
        onSwitch(undefined)
      } else {
        onSwitch(data._id)
      }
    } else {
      // 如果不可展开，就直接跳转
      onClose()
      route(data)
    }
  }, [active, isExpandable, data, route, onClose, onSwitch])

  return (
    <li className={styles.container} style={containerStyle}>
      <div className={styles.parent} onClick={onMenuClick}>
        <span className={cls(styles.parent_title, active && styles.parent_title__focus)}>{data.title}</span>
        {
          isExpandable && (
            <img
              alt='arrow'
              src={imageAssets.arrow_right_white}
              className={cls(styles.parent_arrow, active && styles.parent_arrow__focus)}
            />
          )
        }
      </div>
      {
        isExpandable && (
          <ul className={styles.children}>
            {
              data.children!.map(menu => (
                <ChildItem
                  key={menu._id}
                  data={menu}
                  onClose={onClose}
                />
              ))
            }
          </ul>
        )
      }
    </li>
  )
}

export default MobileMenuItem