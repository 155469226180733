import React, { FunctionComponent, memo } from 'react'
import parse, { HTMLReactParserOptions, DomElement } from 'html-react-parser'
import styles from './RichContent.module.scss'
import AIOImage from 'react-aio-image'
import { getTcbUrl } from 'utils/cloudbase'
import isNumber from 'is-number'

type Props = {
  html?: string
}

const replaceElement: HTMLReactParserOptions['replace'] = (node: DomElement) => {
  if (!node) {
    return false
  }
  // 所有要替换的组件，都放在这条注释之后
  if (node.name === 'img') {
    const imgWidth = isNumber(node?.attribs?.width)
      ? node?.attribs?.width + 'px' :
      node?.attribs?.width

    return (
      <AIOImage
        // @ts-ignore
        width={imgWidth}
        height={256}
        src={getTcbUrl(node?.attribs?.src)}
        mode='auto-height'
        className={styles.img}
      />
    )
  }
  // 所有要替换的组件，都放在这条注释之前
  if (node.children && node.children.length > 0) {
    // @ts-ignore
    return node.children.find(_node => replaceElement(_node)) || false
  }
  return false
}

const parseOptions: HTMLReactParserOptions = {
  replace: node => replaceElement(node)
}

/**
 * 图文详情
 */
const RichContent: FunctionComponent<Props> = ({ html }) => {
  if (!html) return <span>暂无内容</span>

  const fHtml = html.replace(/<p><\/p>/g, '<br />')

  return (
    <div className={styles.container}>
      {
        parse(fHtml, parseOptions)
      }
    </div>
  )
}

export default memo(RichContent)