import dayjs from 'dayjs'
import { StateCreator } from 'zustand'

export type PersistModel<R = any> = {
    /**
     * 记录值
     */
    data: R

    /**
     * 过期时间
     * @description 记录值为时间戳
     */
    expireAt: number
}

export type BaseStoreState<T = any> = {
    data: T[]
    getData: () => Promise<void>
}

/**
 * 获取缓存键名
 * @param code 请求标识符
 * @param search 查询条件
 */
export const getPersistKey = (code: string, search?: string) => {
    const prefix = '_DB_'
    return prefix + (search ? `${code}_${search}` : code)
}

/**
 * 为请求函数赋予本地缓存能力
 * @description 优先选用localStorage中数据，否则执行请求
 * @param fn 数据请求函数
 * @param code 请求标识符（建议不同请求用不同标识符）
 * @param expireIn localStorage缓存多少秒后过期
 */
export const createPersist = <Result = any, Search = any>(
    fn: (s?: Search) => Promise<CloudBaseResp<Result>>,
    code: string,
    expireIn = 60 * 60
): (search?: Search) => Promise<CloudBaseResp<Result>> => {

    return async search => {
        const storeKey = getPersistKey(code, search && JSON.stringify(search))

        async function cacheAndFetch() {
            try {
                // 请求数据
                const resp = await fn(search)
                if (
                    !!resp
                    || (Array.isArray(resp) && resp.length > 0)
                ) {
                    // 尝试缓存
                    localStorage.setItem(storeKey, JSON.stringify({
                        data: resp,
                        expireAt: dayjs().add(expireIn, 'second').unix(),
                    }))
                }

                return resp
            } catch (err) {
                console.log('Error: 请求缓存创建失败，', err)
                return undefined
            }
        }

        try {
            if (expireIn === 0) {
                // 可以不启用缓存，这样方便调试
                localStorage.removeItem(storeKey)
                return await fn(search)
            }

            // 查看缓存
            const res = localStorage.getItem(storeKey)
            if (!res) {
                return await cacheAndFetch()
            }

            const resp = JSON.parse(res) as PersistModel<Result>
            if (dayjs().unix() <= resp.expireAt) {
                // 未过期直接用缓存
                return resp.data
            } else {
                return cacheAndFetch()
            }
        } catch (e) {
            return cacheAndFetch()
        }
    }
}

/**
 * 构建基础全局状态的工具
 * @param code 可以根据
 * @param fn 
 */
export const createBaseStore = <Item = any>(code: string, fn: (s?: any) => Promise<CloudBaseResp<Item[]>>): StateCreator<BaseStoreState<Item>> => {
    return set => ({
        data: (() => {
            try {
                const res = localStorage.getItem(getPersistKey(code))
                if (!res) return []

                // 还要检查是否过期
                const resp = JSON.parse(res) as PersistModel<Item[]>
                if (dayjs().unix() <= resp.expireAt) {
                    return resp.data
                } else {
                    return []
                }
            } catch (err) {
                return []
            }
        })(),
        getData: async () => {
            const resp = await fn()
            if (resp && resp.length > 0) set({ data: resp })
        }
    })
}