import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import 'intersection-observer'
import { configResponsive } from 'ahooks'
import App from 'app'
import React from 'react'
import ReactDOM from 'react-dom'
import { getCloudBaseApp } from 'utils/cloudbase'
import './index.scss'
import * as serviceWorker from './serviceWorker'

// 初始化腾讯云开发实例
getCloudBaseApp()

// 初始化断点
configResponsive({
  xl: 1260,
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
