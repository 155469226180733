import { useCallback, useEffect } from 'react'
import create from 'zustand'
import { getHomeModules } from './query'
import { createBaseStore } from '../helper'
import { HomeModuleModel } from './types'

const useHomeModule = create(createBaseStore<HomeModuleModel>('homeModules', getHomeModules))

const useHomeModules = () => {
    const { data, getData } = useHomeModule()

    const loadData = useCallback(
        async () => {
            if (data.length === 0) {
                await getData()
            }
        },
        // eslint-disable-next-line
        []
    )

    useEffect(() => {
        loadData()
    }, [loadData])

    return { data }
}

export default useHomeModules