import React, { FunctionComponent } from 'react'
import styles from './Pagination.module.scss'
import { default as RCPagination, PaginationProps } from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import './index.scss'
import appConfig from 'config/app'
import cls from 'classnames'
import useBreakpoint from 'hooks/use-breakpoint'

type Props = PaginationProps & {
  /** 正在加载分页 */
  loading?: boolean
}

/**
 * 基于rc-pagination的分页组件
 * @description 调整了下样式和默认属性
 */
const Pagination: FunctionComponent<Props> = ({ loading, ...pageProps }) => {
  const isMobile = useBreakpoint()

  return (
    <div className={cls(styles.container, loading && styles.container__disabled)}>
      <RCPagination
        {...pageProps}
        showPrevNextJumpers={!isMobile}
        hideOnSinglePage={!appConfig.debug}
      />
    </div>
  )
}

export default Pagination