import appConfig from 'config/app'
import React, { Fragment, FunctionComponent, memo } from 'react'
import styles from './BreadCrumbs.module.scss'

type Props = {
  data: string[]
}

/**
 * 面包屑导航
 */
const BreadCrumbs: FunctionComponent<Props> = ({ data }) => {
  return (
    <div className={styles.container}>
      {
        data.length === 0
          ? <span className={styles.label}>{appConfig.appName}</span> :
          data.map((b, i, { length }) => (
            <Fragment key={b}>
              <span className={styles.label}>{b}</span>
              {
                i + 1 < length && (
                  <span className={styles.arrow_word}>{'>'}</span>
                )
              }
            </Fragment>
          ))
      }
    </div>
  )
}

export default memo(BreadCrumbs)
