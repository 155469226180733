import { getCloudBaseDB } from "utils/cloudbase"
import { ArticleModel, PageArticleReq } from "./types"

const DB = 'szsilk-article'

/**
 * 获取文章分页请求
 * @param req 
 */
export const pageArticle = async (req: PageArticleReq): Promise<PageResp<ArticleModel>> => {
    const db = await getCloudBaseDB()
    const query = db
        .collection(DB)
        .where({
            ref_menu: req.menuId
        })

    const countResp = await query.count()
    const total = countResp?.total || 0
    const field = req.showContent
        ? {} :
        { content: false }

    const resp = await query
        .field(field)
        .orderBy('publish_date', 'desc')    // 甲方要求文章按指定时间顺序显示
        .orderBy('_createTime', 'desc')
        .skip((req.current - 1) * req.pageSize)
        .limit(req.pageSize)
        .get()

    if (resp.data) {
        return {
            ...req,
            total,
            list: resp.data as ArticleModel[],
        }
    } else {
        return {
            ...req,
            total,
            list: [],
        }
    }
}

/**
 * 获取文章详情
 * @param articleId 
 */
export const detailArticle = async (articleId: string): Promise<CloudBaseResp<ArticleModel>> => {
    const db = await getCloudBaseDB()
    const resp = await db
        .collection(DB)
        .where({
            _id: articleId
        })
        .limit(1)
        .get()

    if (resp.data && resp.data.length > 0) {
        return resp.data[0] as ArticleModel
    } else {
        return undefined
    }
}