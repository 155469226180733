import cloudbase from '@cloudbase/js-sdk'
import appConfig from 'config/app'

let _app: cloudbase.app.App
// let _auth: cloudbase.auth.App

/**
 * 获取云开发实例
 * @description 单例模式
 */
export const getCloudBaseApp = async () => {
    if (!_app) {
        const { envId } = appConfig
        _app = cloudbase.init({ env: envId })
    }

    // FIXME: 暂不使用匿名登录，有bug
    // if (!_auth) {
    //     _auth = _app.auth({ persistence: 'local' })
    // }

    // const loginState = await _auth.getLoginState()
    // if (!loginState?.isAnonymousAuth) {
    //     // 尝试匿名登录
    //     await _auth.anonymousAuthProvider().signIn()
    // }

    return _app
}

/**
 * 获取云开发数据库实例
 */
export const getCloudBaseDB = async () => {
    const app = await getCloudBaseApp()
    const db = app.database()
    return db
}

/**
 * 获取云存储的访问链接
 * @param url 云存储的特定url
 */
export const getTcbUrl = (url?: string) => {
    if (!url) return ''

    if (!url.startsWith("cloud://")) {
        return url
    }

    const re = /cloud:\/\/.*?\.(.*?)\/(.*)/
    const result = re.exec(url) as any
    return `https://${result[1]}.tcb.qcloud.la/${result[2]}`
}