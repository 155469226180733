const debug = process.env.NODE_ENV === 'development'

const subpath = `${process.env.REACT_APP_SUBPATH}`

const myDomain = !debug ?
    `https://${process.env.REACT_APP_MY_DOMAIN}` :
    `http://192.168.2.29:3000`

/**
 * 平台相关配置
 */
const appConfig = {
    debug,
    // 子路径
    subpath,
    // 部署所在域名
    myDomain,
    // 部署路径（配合process.env.PUBLIC_URL，可获得完整的资源路径）
    deployUrl: `${myDomain}/${subpath}`,
    // 开发商
    com: 'com.logix',
    // 应用名称
    appName: '苏州丝绸博物馆',
    // 腾讯云开发环境ID
    envId: `${process.env.REACT_APP_ENV_ID}`,
    // 屏幕高宽比和ip6的比例
    ip6Ratio: (window.innerHeight / window.innerWidth) / 1.608
}

export default appConfig