export enum ListType {
    news = 'news',
    masonry = 'masonry',
    grid = 'grid'
}

export enum SpecialMenuType {
    activity = 'activity',
    education = 'social-education',
    lecture = 'museum-lecture'
}

export type MenuModel = BaseModel & {
    sort: number
    /** 名称 */
    title: string
    /** 标识符 */
    code: string
    /** 是否只作为单篇文章展示 */
    show_detail: boolean
    /** 列表类型 */
    list_type: ListType
    /** 外链（如果配置外链，将打开新tab页跳转） */
    external_url?: string
    /** 上级菜单 */
    parent?: string | MenuModel
    /** 封面 */
    cover?: string
    /** 子菜单（这个是前端计算出来的） */
    children?: MenuModel[]
}