import { createPersist } from 'api/helper'
import { getCloudBaseDB } from 'utils/cloudbase'
import { SwiperModel } from './types'

const DB = 'szsilk-swiper'

/**
 * 获取首页幻灯片
 * @description 缓存15分钟
 */
export const getSwipers = createPersist(async (): Promise<SwiperModel[]> => {
    const db = await getCloudBaseDB()
    const resp = await db
        .collection(DB)
        .where({
            is_published: true
        })
        .orderBy('sort', 'desc')
        .limit(10)
        .get()

    if (resp.data) {
        return resp.data as SwiperModel[]
    } else {
        return []
    }
}, 'swipers', 15 * 60)