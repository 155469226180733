import React, { Fragment, FunctionComponent } from 'react'
import styles from './ExhibitionDetail.module.scss'
import { ArticleModel } from 'api/article/types'
import { getExhibitionDate, getExhibitionType } from 'api/article/helper'
import appConfig from 'config/app'
import RichContent from 'components/RichContent'

type Props = {
  data: ArticleModel
}

/**
 * 文章页-展览详情
 */
const ExhibitionDetail: FunctionComponent<Props> = ({ data }) => {
  return (
    <Fragment>
      <h3 className={styles.title}>{data.title}</h3>
      <div className={styles.label__wrapper}>
        <h6 className={styles.label}>展览类型：{getExhibitionType(data.exhibition_type)}</h6>
        <h6 className={styles.label}>展览时间：{getExhibitionDate(data)}</h6>
        <h6 className={styles.label}>展览地点：{data.exhibition_address || appConfig.appName}</h6>
      </div>
      <RichContent html={data.content} />
    </Fragment>
  )
}

export default ExhibitionDetail