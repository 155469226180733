import { useBoolean } from 'ahooks'
import imageAssets from 'assets/images'
import React, { Fragment, FunctionComponent, memo, useCallback } from 'react'
import { DigitalButton } from '../Digital'
import MenuSwitch from '../MenuSwitch'
import MobileMenus from '../MobileMenus'
import styles from './MobileHeader.module.scss'

type Props = {
  onForceScroll: () => void
}

/**
 * 移动端专用顶部导航栏
 */
const MobileHeader: FunctionComponent<Props> = ({ onForceScroll }) => {
  const [visible, { toggle }] = useBoolean(false)
  const onToggle = useCallback(
    () => {
      toggle()
      onForceScroll()
    },
    // eslint-disable-next-line 
    []
  )

  return (
    <Fragment>
      <div className={styles.container}>
        <DigitalButton className={styles.digital} />
        <img alt='logo' src={imageAssets.logo} className={styles.logo} />
        <MenuSwitch
          visible={visible}
          onSwitch={onToggle}
          className={styles.switch}
        />
        <MobileMenus
          visible={visible}
          onClose={onToggle}
        />
      </div>
    </Fragment>
  )
}

export default memo(MobileHeader)