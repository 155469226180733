import dayjs from 'dayjs'
import { ArticleModel, ExhibitionType } from './types'

/**
 * 获取文章日期
 * @param data 
 */
export const getArticleDate = (data: ArticleModel) => dayjs(data.publish_date || data._createTime).format('YYYY/MM/DD')

/**
 * 获取展览类型
 * @param type 
 */
export const getExhibitionType = (type?: ExhibitionType) => {
    if (!type) {
        return '展览资讯'
    } else if (type === ExhibitionType.special) {
        return '临时展'
    } else {
        return '常设展'
    }
}

/**
 * 获取展览时间
 * @param data 
 */
export const getExhibitionDate = (data: ArticleModel) => {
    if (!data.is_exhibition) return '展览资讯'

    if (data.exhibition_type === ExhibitionType.special) {
        if (data.exhibition_start_date && data.exhibition_end_date) {
            return dayjs(data.exhibition_start_date).format('YYYY/MM/DD')
                + ' ~ '
                + dayjs(data.exhibition_end_date).format('YYYY/MM/DD')
        } else if (!data.exhibition_start_date) {
            return dayjs(data.exhibition_end_date!).format('YYYY/MM/DD') + ' 闭展'
        } else {
            return dayjs(data.exhibition_start_date).format('YYYY/MM/DD') + ' 开展'
        }
    } else {
        return '常设展'
    }
}