import { useBoolean } from 'ahooks'
import useLinks from 'api/links/store'
import imageAssets from 'assets/images'
import Modal from 'components/Modal'
import React, { Fragment, FunctionComponent, memo } from 'react'
import styles from './FooterLinks.module.scss'

/**
 * 友情链接
 */
const FooterLinks: FunctionComponent = () => {
  const [visible, { toggle }] = useBoolean()
  const { data } = useLinks()

  return (
    <Fragment>
      <div className={styles.container} onClick={() => toggle()}>
        <label className={styles.label}>友情链接</label>
        <img alt='arrow' className={styles.arrow} src={imageAssets.arrow_right} />
      </div>
      <Modal visible={visible} onClose={toggle}>
        <ol className={styles.list}>
          {
            data.map(item => (
              <li key={item._id} className={styles.item} onClick={() => window.open(item.url)}>
                {item.title}
              </li>
            ))
          }
        </ol>
      </Modal>
    </Fragment>
  )
}

export default memo(FooterLinks)