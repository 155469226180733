import React, { Fragment, FunctionComponent, memo, useMemo } from 'react'
import styles from './GridList.module.scss'
import { useCounter, useRequest } from 'ahooks'
import { pageArticle } from 'api/article/query'
import Pagination from 'components/Pagination'
import { ArticleModel } from 'api/article/types'
import { useArticleHistory } from 'api/menu/helper'
import qs from 'query-string'
import AIOImage from 'react-aio-image'
import { getTcbUrl } from 'utils/cloudbase'
import { getExhibitionDate } from 'api/article/helper'
import { MenuPageProps } from '../types'

type Props = MenuPageProps

const PAGE_SIZE = 12

const GridItem = memo<{ data: ArticleModel } & Pick<Props, 'code'>>(({ code, data }) => {
  const { route } = useArticleHistory('?' + qs.stringify({ id: data._id }))

  return (
    <li className={styles.item} onClick={() => route(code, data)}>
      <AIOImage
        width='100%'
        src={getTcbUrl(data.cover)}
        className={styles.item_cover}
      />
      <h5 className={styles.item_title}>{data.title}</h5>
      <h6 className={styles.item_subtitle}>{getExhibitionDate(data)}</h6>
    </li>
  )
})


/**
 * 展览类的网格列表
 */
const GridList: FunctionComponent<Props> = ({ code, menuId }) => {
  const [current, { set: setPage }] = useCounter(1)
  const { data: resp, loading } = useRequest(async () => await pageArticle({ current, menuId, pageSize: PAGE_SIZE }), {
    refreshDeps: [current, menuId]
  })
  const data = useMemo(() => ({
    list: resp?.list || [],
    total: resp?.total || 0
  }), [resp])

  return (
    <Fragment>
      <ol className={styles.container}>
        {
          data.list.map(item => (
            <GridItem key={item._id} data={item} code={code} />
          ))
        }
      </ol>
      <Pagination
        loading={loading}
        pageSize={PAGE_SIZE}
        current={current}
        total={data.total}
        onChange={p => setPage(p)}
      />
    </Fragment>
  )
}

export default GridList