import React, { FunctionComponent } from 'react'
import MenuLayout from 'components/layouts/MenuLayout'
import { useLocation, useParams } from 'react-router-dom'
import qs from 'query-string'
import { useRequest } from 'ahooks'
import { detailArticle } from 'api/article/query'
import ArticleDetail from 'components/detail/ArticleDetail'
import ExhibitionDetail from 'components/detail/ExhibitionDetail'
import { isSpecialMenu } from 'api/menu/helper'
import ActivityDetail from 'components/detail/ActivityDetail'

const ArticleBase: FunctionComponent<{ articleId: string }> = ({ articleId }) => {
  const { data } = useRequest(async () => await detailArticle(articleId))
  const isExhibition = !!data?.is_exhibition

  return (
    !data
      ? null :  // FIXME: 加载状态
      !isExhibition
        ? <ArticleDetail data={data} /> :
        <ExhibitionDetail data={data} />
  )
}

/**
 * 普通文章详情页
 */
const Index: FunctionComponent = () => {
  const { search } = useLocation()
  const articleId = (qs.parse(search)?.id || '') as string

  const code = useParams<any>()?.code || ''
  const isSpecial = isSpecialMenu(code)

  return (
    <MenuLayout>
      {
        isSpecial ?
          <ActivityDetail articleId={articleId} /> :
          <ArticleBase articleId={articleId} />
      }
    </MenuLayout>
  )
}

export default Index