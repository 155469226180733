// 自动生成，请勿删除
import appConfig from 'config/app'

const { myDomain } = appConfig

const imageAssets = {
  arrow_right_white: myDomain + process.env.PUBLIC_URL + '/images/arrow_right_white.png',
  arrow_right: myDomain + process.env.PUBLIC_URL + '/images/arrow_right.png',
  logo_word: myDomain + process.env.PUBLIC_URL + '/images/logo_word.png',
  weibo: myDomain + process.env.PUBLIC_URL + '/images/weibo.png',
  logo: myDomain + process.env.PUBLIC_URL + '/images/logo.png',
  arrow_right_gray: myDomain + process.env.PUBLIC_URL + '/images/arrow_right_gray.png',
  wechat: myDomain + process.env.PUBLIC_URL + '/images/wechat.png',
  digital: myDomain + process.env.PUBLIC_URL + '/images/digital.png',
}

export default imageAssets