import { useRequest } from 'ahooks'
import { pageArticle } from 'api/article/query'
import { ArticleModel } from 'api/article/types'
import { useArticleHistory, useMenuHistory } from 'api/menu/helper'
import dayjs from 'dayjs'
import useBreakpoint from 'hooks/use-breakpoint'
import React, { Fragment, FunctionComponent, memo, useCallback, useMemo } from 'react'
import styles from './MenuList.module.scss'
import qs from 'query-string'

type Props = {
  menuId: string
  menuCode: string
  menuTitle: string
}

const Item = memo<{ code: string, data: ArticleModel }>(({ code, data }) => {
  const { route } = useArticleHistory('?' + qs.stringify({ id: data._id }))

  return (
    <li key={data._id} className={styles.item}>
      <h5 className={styles.item_title} onClick={() => route(code, data)}>{data.title}</h5>
      <h6 className={styles.item_subtitle}>{dayjs(data.publish_date || data._createTime).format('YYYY/MM/DD')}</h6>
    </li>
  )
})

/**
 * 首页关联菜单列表
 */
const MenuList: FunctionComponent<Props> = ({ menuId, menuCode, menuTitle }) => {
  const isMobile = useBreakpoint()
  const { route } = useMenuHistory()

  const { data: resp } = useRequest(async () => await pageArticle({ current: 1, menuId, pageSize: isMobile ? 2 : 4 }))
  const list = useMemo(() => resp?.list || [], [resp])

  const onShowMore = useCallback(() => route(menuCode), [route, menuCode])

  return (
    <Fragment>
      <h3 className={styles.title} onClick={onShowMore}>{menuTitle}</h3>
      <ol className={styles.list}>
        {
          list.map(item => (
            <Item
              key={item._id}
              data={item}
              code={menuCode}
            />
          ))
        }
        {
          isMobile && (
            <button className={styles.more} onClick={onShowMore}>更多</button>
          )
        }
      </ol>
    </Fragment>
  )
}

export default MenuList