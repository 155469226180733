import React, { FunctionComponent } from 'react'
import styles from './Swiper.module.scss'
import cls from 'classnames'
import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import useSwipers from 'api/swiper/store'
import AIOImage from 'react-aio-image'
import { getTcbUrl } from 'utils/cloudbase'
import { useCounter } from 'ahooks'
import { SwiperModel } from 'api/swiper/types'

const renderLoading = (isFetching: boolean) => {
  return <div className={cls(styles.loading, !isFetching && styles.loading__finished)} />
}

const SwiperBase: FunctionComponent<{ list: SwiperModel[] }> = ({ list }) => {
  const [current, { set }] = useCounter(0)

  const [swiperRef, slider] = useKeenSlider<HTMLUListElement>({
    initial: 0,
    loop: false,
    controls: true,
    slideChanged: s => {
      set(s.details().relativeSlide)
    }
  })

  const dots = list.length

  return (
    <ul ref={swiperRef} className={cls(styles.container, 'keen-slider')}>
      {
        list.map(item => (
          <li
            key={item._id}
            className={cls('keen-slider__slide', styles.item)}
            onClick={() => window.open(item.url)}
          >
            <AIOImage
              width='100%'
              height='100%'
              src={getTcbUrl(item.cover)}
              renderPlaceholder={renderLoading}
            />
          </li>
        ))
      }
      {
        dots > 1 && (
          <ul className={styles.dot__wrapper}>
            {
              Array.from({ length: dots }).map((_, idx) => (
                <li
                  key={idx}
                  className={cls(styles.dot, idx === current && styles.dot__active)}
                  onClick={() => slider.moveToSlideRelative(idx)}
                />
              ))
            }
          </ul>
        )
      }
    </ul>
  )
}

/**
 * 首页幻灯片
 */
const Swiper: FunctionComponent = () => {
  const { data } = useSwipers()

  return (
    <div className={styles.wrapper}>
      {
        data.length > 0 && (
          <SwiperBase list={data} />
        )
      }
    </div>
  )
}

export default Swiper