import React, { FunctionComponent } from 'react'
import styles from './Modal.module.scss'
import cls from 'classnames'

type AnimationType = 'centerScale' | 'bottomUp' | 'centerSlide'

export type ModalProps = {
  /** 
   * 是否打开
   */
  visible: boolean

  /** 
   * 处理弹窗关闭
   */
  onClose: () => void

  /** 
   * 点击遮罩层关闭
   */
  closeOnClickOverlay?: boolean

  /** 
   * 动画样式
   */
  animation?: AnimationType
}

/**
 * 基础弹窗
 */
const Modal: FunctionComponent<ModalProps> = ({
  visible,
  onClose,
  children,
  animation = 'centerSlide',
  closeOnClickOverlay = true,
}) => {
  const rootStyle = cls(
    styles.modal,
    styles[animation],
    visible && styles.modal__active,
    // @ts-ignore
    visible && styles[`${animation}__active`]
  )

  const handleTouchMove = (e: any) => {
    e.stopPropagation()
  }

  const handleClickOverlay = () => {
    if (
      closeOnClickOverlay &&
      typeof onClose === 'function'
    ) {
      onClose()
    }
  }

  return (
    <div className={rootStyle} onClick={handleTouchMove}>
      <div className={styles.overlay} onClick={handleClickOverlay} />
      <div className={styles.content}>
        {children}
      </div>
    </div>
  )
}

export default Modal