import React, { FunctionComponent, useMemo } from 'react'
// import styles from './index.module.scss'
import MenuLayout from 'components/layouts/MenuLayout'
import { useParams } from 'react-router-dom'
import useMenus from 'api/menu/store'
import { getMenuPageMetas, isSpecialMenu } from 'api/menu/helper'
import { ListType } from 'api/menu/types'
import NewsList from 'components/menu/NewsList'
import GridList from 'components/menu/GridList'
import ArticleDetail from 'components/menu/ArticleDetail'
import MasonryList from 'components/menu/MasonryList'
import ActivityList from 'components/menu/ActivityList'

/**
 * 统一菜单页
 */
const Index: FunctionComponent = () => {
  const code = useParams<any>()?.code || ''
  const { data: menus } = useMenus()

  const { menuId, listType, showDetail } = useMemo(() => {
    const data = getMenuPageMetas(menus, code)
    return {
      menuId: data.menu?._id,
      showDetail: data.menu?.show_detail || false,
      listType: data.menu?.list_type,
    }
  }, [menus, code])

  const isSpecial = isSpecialMenu(code)

  return (
    <MenuLayout>
      {
        !menuId
          ? null :  // FIXME: 建设中或者不存在的占位图
          showDetail
            ? <ArticleDetail code={code} menuId={menuId} /> :
            isSpecial
              ? <ActivityList code={code} menuId={menuId} /> :
              listType === ListType.news
                ? <NewsList code={code} menuId={menuId} /> :
                listType === ListType.grid
                  ? <GridList code={code} menuId={menuId} /> :
                  <MasonryList code={code} menuId={menuId} />
      }
    </MenuLayout>
  )
}

export default Index