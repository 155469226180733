import { useBoolean } from 'ahooks'
import { useRef, useMemo } from 'react'

/**
 * 根据弹窗状态，资料能够动态变化
 * @param initialInfo 初始化数据
 */
export function useModalInfo<T = any>(initialInfo: T) {
    const infoEl = useRef<T>(initialInfo)
    const [visible, { toggle }] = useBoolean(false)

    const { open, close } = useMemo(
        () => ({
            open: (nextInfo: T) => {
                infoEl.current = nextInfo
                toggle()
            },
            close: toggle
        }),
        // eslint-disable-next-line
        []
    )

    return {
        open,
        close,
        visible,
        modalInfo: infoEl.current
    }
}