import React, { FunctionComponent, memo, useMemo } from 'react'
import styles from './FooterPlatforms.module.scss'
import usePlatforms from 'api/platform/store'
import { PlatformCode, PlatformModel } from 'api/platform/types'
import imageAssets from 'assets/images'
import Modal from 'components/Modal'
import AIOImage from 'react-aio-image'
import { getTcbUrl } from 'utils/cloudbase'
import { useModalInfo } from 'components/Modal/helper'

/**
 * 外部平台链接
 */
const FooterPlatforms: FunctionComponent = () => {
  const { data } = usePlatforms()
  const { modalInfo, visible, open, close } = useModalInfo({    
    qrcode: '',
    caption: '',
  })

  const platforms = useMemo(() => {
    const wechat = data.find(i => i.code === PlatformCode.wechat)
    const weibo = data.find(i => i.code === PlatformCode.weibo)
    return [weibo, wechat].filter(p => !!p) as PlatformModel[]
  }, [data])

  return (
    <div className={styles.container}>
      {
        platforms.map(p => (
          <img
            alt='logo'
            key={p._id}
            className={styles.item}
            // @ts-ignore
            src={imageAssets[p.code]}
            onClick={() => open({              
              qrcode: getTcbUrl(p.pic),
              caption: p.txt || p.title,
            })}
          />
        ))
      }
      <Modal visible={visible} onClose={close}>
        <div className={styles.card}>
          <AIOImage
            width='256px'
            height='256px'
            src={modalInfo.qrcode}
          />
          <p className={styles.card_caption}>{modalInfo.caption}</p>
        </div>
      </Modal>
    </div>
  )
}

export default memo(FooterPlatforms)