import React, { Fragment, FunctionComponent } from 'react'
import styles from './ActivityDetail.module.scss'
import { useRequest } from 'ahooks'
import { detailActivity } from 'api/activity/query'
import RichContent from 'components/RichContent'
import { getActivityAddress, getActivityDate, getActivityLimit } from 'api/activity/helper'

type Props = {
  articleId: string
}

/**
 * 详情页-活动详情页
 */
const ActivityDetail: FunctionComponent<Props> = ({ articleId }) => {
  const { data } = useRequest(async () => await detailActivity(articleId), {
    refreshDeps: [articleId]
  })

  if (!data) return null

  return (
    <Fragment>
      <h3 className={styles.title}>{data.title}</h3>
      <div className={styles.label__wrapper}>
        <h6 className={styles.label}>时 间：{getActivityDate(data)}</h6>
        <h6 className={styles.label}>地 点：{getActivityAddress(data)}</h6>
        <h6 className={styles.label}>名 额：{getActivityLimit(data)}</h6>
        <h6 className={styles.label}>费 用：{data.price || '免费'}</h6>
      </div>
      <RichContent html={data.content} />
    </Fragment>
  )
}

export default ActivityDetail