import { getCloudBaseDB } from 'utils/cloudbase'
import { MenuModel } from './types'
import { mergeMenus } from './helper'
import { createPersist } from 'api/helper'

const DB = 'szsilk-menu'

/**
 * 获取系统中所有菜单栏目
 * @description 用户本地缓存24小时
 */
export const getMenus = createPersist(async (): Promise<MenuModel[]> => {
    const db = await getCloudBaseDB()
    const resp = await db
        .collection(DB)
        .where({})
        .orderBy('sort', 'desc')
        .get()

    if (resp.data) {
        return mergeMenus(resp.data as MenuModel[])
    } else {
        return []
    }
}, 'menus', 24 * 60 * 60)