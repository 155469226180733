import React, { FunctionComponent, memo, useMemo } from 'react'
import styles from './PCHeader.module.scss'
import imageAssets from 'assets/images'
import useMenus from 'api/menu/store'
import { MenuModel } from 'api/menu/types'
import { useMenuHistory, withHome } from 'api/menu/helper'
import { DigitalButton } from '../Digital'

/**
 * PC端专用顶部导航栏
 */
const PCHeader: FunctionComponent = () => {
  const { route } = useMenuHistory()
  const { data } = useMenus()
  const menus = useMemo<MenuModel[]>(() => withHome(data), [data])

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <img alt='logo' src={imageAssets.logo_word} className={styles.logo} />
        <DigitalButton className={styles.digital} />
        {/* <img alt='digital' src={imageAssets.digital} className={styles.digital} onClick={() => window.open('http://szsilk.wglj.suzhou.com.cn/')} /> */}
        <ul className={styles.menu}>
          {
            menus.map(m => {
              const hasChildren = !!m.children?.length

              return (
                <li key={m._id} className={styles.menu_item} onClick={() => !hasChildren && route(m)}>
                  {m.title}
                  <span className={styles.menu_indicator} />
                  {
                    hasChildren && (
                      <ul className={styles.submenu}>
                        {
                          m.children!.map(sm => (
                            <li key={sm._id} className={styles.submenu_item} onClick={() => route(sm)}>
                              {sm.title}
                            </li>
                          ))
                        }
                      </ul>
                    )
                  }
                </li>
              )
            })
          }
        </ul>
      </div>
    </div>
  )
}

export default memo(PCHeader)