import { SpecialMenuType } from 'api/menu/types'
import { request } from './helper'
import { ActivityCateType, ActivityModel } from './types'

const PAGE_SIZE = 100

/**
 * 获取指定分类的活动列表
 * @param code 菜单编码
 */
export const listActivity = async (code: string) => {
    const resp = await request('POST', '/queryActivity', JSON.stringify({
        current: 1,
        pageSize: PAGE_SIZE,
        category: (() => {
            switch (code) {
                case SpecialMenuType.education:
                    return ActivityCateType.education
                default:
                    return ActivityCateType.lecture
            }
        })(),
    })) as { result?: { list: ActivityModel[] } } | undefined

    if (resp?.result?.list) {
        return resp.result.list || []
    } else {
        return []
    }
}

/**
 * 获取活动详情
 * @param id 
 */
export const detailActivity = async (id: string) => {
    const resp = await request('GET', `/readActivity/${id}`) as { result?: ActivityModel } | undefined
    return resp?.result
}