import { createPersist } from 'api/helper'
import { getCloudBaseDB } from 'utils/cloudbase'
import { LinkModel } from './types'

const DB = 'szsilk-link'

/**
 * 获取系统中所有友情链接
 * @description 用户本地缓存24小时
 */
export const getLinks = createPersist(async (): Promise<LinkModel[]> => {
    const db = await getCloudBaseDB()
    const resp = await db
        .collection(DB)
        .where({})
        .get()

    if (resp.data) {
        return resp.data as LinkModel[]
    } else {
        return []
    }
}, 'links', 24 * 60 * 60)