import React, { FunctionComponent, useMemo, useState } from 'react'
import styles from './MobileMenus.module.scss'
import useMenus from 'api/menu/store'
import { MenuModel } from 'api/menu/types'
import { withHome } from 'api/menu/helper'
import cls from 'classnames'
import MobileMenuItem from '../MobileMenuItem'

type Props = {
  visible: boolean
  onClose: () => void
}

/**
 * 移动端专用菜单
 */
const MobileMenus: FunctionComponent<Props> = ({ visible, onClose }) => {
  const { data } = useMenus()
  const menus = useMemo<MenuModel[]>(() => withHome(data), [data])

  const [expandMenuId, setMenuId] = useState<string | undefined>(undefined)

  return (
    <div className={cls(styles.container, visible && styles.container__active)}>
      <div className={cls(styles.overlay, visible && styles.overlay__active)} />
      <div className={cls(styles.content, visible && styles.content__active)}>
        {
          menus.map(menu => (
            <MobileMenuItem
              key={menu._id}
              data={menu}
              active={menu._id === expandMenuId}
              onClose={onClose}
              onSwitch={setMenuId}
            />
          ))
        }
      </div>
    </div>
  )
}

export default MobileMenus