import React, { Fragment, FunctionComponent, memo, useMemo } from 'react'
import styles from './Footer.module.scss'
import usePlatforms from 'api/platform/store'
import dayjs from 'dayjs'
import { PlatformCode } from 'api/platform/types'
import FooterMenus from '../FooterMenus'
import cls from 'classnames'
import imageAssets from 'assets/images'
import FooterPlatforms from '../FooterPlatforms'
import FooterLinks from '../FooterLinks'

/**
 * 主布局底部导航栏
 * @description 菜单、友情链接、其他信息；支持响应式
 */
const Footer: FunctionComponent = () => {
  const { data: platforms } = usePlatforms()

  const copyright = useMemo(() => {
    return `©2010-${dayjs().year()} 苏州丝绸博物馆 版权所有 `
  }, [])

  const beian = useMemo(() => {
    return (
      platforms.find((i) => i.code === PlatformCode.beian)?.txt ||
      '苏ICP备16051337号-1'
    )
  }, [platforms])

  const infoList = useMemo(() => {
    const address = platforms.find((i) => i.code === PlatformCode.address)
    const openTime = platforms.find((i) => i.code === PlatformCode.open_time)
    return [
      address
        ? { label: address.title, content: address.txt }
        : { label: '博物馆地址', content: '江苏苏州人民路2001号' },
      openTime
        ? { label: openTime.title, content: openTime.txt }
        : {
            label: '开放时间',
            content:
              '每星期二至星期日9:00-17:00(16:00停止入馆)，每星期一闭馆(国家法定节假日除外)',
          },
    ]
  }, [platforms])

  return (
    <footer className={styles.container}>
      <div className={styles.content}>
        <div className={cls(styles.column, styles.column_first)}>
          {infoList.map((i) => (
            <Fragment key={i.label}>
              <label className={styles.info_label}>{i.label}</label>
              <span className={styles.info_content}>{i.content}</span>
            </Fragment>
          ))}
          <label className={styles.info_label}></label>
        </div>
        <div className={styles.column}>
          <FooterMenus />
        </div>
        <div className={cls(styles.column, styles.column_last)}>
          <FooterLinks />
          <img alt="logo" className={styles.logo} src={imageAssets.logo_word} />
          <FooterPlatforms />
        </div>
      </div>
      <div className={styles.flexWrap}>
        <div
          className={styles.blue}
          onClick={() =>
            window.open(
              'http://bszs.conac.cn/sitename?method=show&id=48F459305AFE4CB8E053012819ACF3F6',
            )
          }
        >
          <img src="https://dcs.conac.cn/image/blue_error.png" alt="事业单位" />
        </div>

        <div className={styles.copyright}>
          {copyright}
          <a
            href="//beian.miit.gov.cn"
            target="_blank"
            rel="noopener noreferrer"
          >
            {beian}
          </a>
        </div>
      </div>
    </footer>
  )
}

export default memo(Footer)
