export enum ActivityAttrType {
    family = 1,
    common = 2,
}

export enum ActivityCateType {
    education = 'activity_family',
    lecture = 'activity_lecture'
}

export type ActivityCateModel = {
    id: number
    alias: string
    title: string
}

export type ActivitySpeakerModel = {
    id: number
    /** 名字 */
    name: string
    /** 头像 */
    avatar: string
    /** 文本介绍 */
    intro: string
}

export type ActivityPlanModel = {
    id: number
    /** 开始时间 */
    beginDate: string
    /** 结束时间 */
    endDate: string
    /** 地点 */
    address: string
    /** 最大人数 */
    ruleApplyMax: number
    /** 已预约人数 */
    cntApplyPersons: number
}

export type ActivityModel = {
    id: number
    /** 活动性质 */
    attr: ActivityAttrType
    /** 标题 */
    title: string
    /** 列表页封面 */
    cover: string
    /** 详情页封面 */
    detailCover: string
    /** 简介 */
    intro: string
    /** 图文详情 */
    content: string
    /** 票价 */
    price: string
    /** 地址 */
    address: string
    /** 主持人 */
    presenter: string
    /** 关联的计划 */
    rPlans: ActivityPlanModel[]
    /** 关联的类型 */
    rCate: ActivityCateModel
}