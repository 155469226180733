import React, { Fragment, FunctionComponent, memo, useMemo } from 'react'
import styles from './NewsList.module.scss'
import { useCounter, useRequest } from 'ahooks'
import { pageArticle } from 'api/article/query'
import dayjs from 'dayjs'
import { ArticleModel } from 'api/article/types'
import { useArticleHistory } from 'api/menu/helper'
import qs from 'query-string'
import Pagination from 'components/Pagination'
import { MenuPageProps } from '../types'

type Props = MenuPageProps

const PAGE_SIZE = 10

const NewsItem = memo<{ data: ArticleModel } & Pick<Props, 'code'>>(({ code, data }) => {
  const { route } = useArticleHistory('?' + qs.stringify({ id: data._id }))

  return (
    <li className={styles.item} onClick={() => route(code, data)}>
      <h5 className={styles.item_title}>{data.title}</h5>
      <h6 className={styles.item_date}>{dayjs(data.publish_date || data._createTime).format('YYYY/MM/DD')}</h6>
    </li>
  )
})

/**
 * 普通文章-文字列表
 */
const NewsList: FunctionComponent<Props> = ({ menuId, code }) => {
  const [current, { set: setPage }] = useCounter(1)
  const { data: resp, loading } = useRequest(async () => await pageArticle({ current, menuId, pageSize: PAGE_SIZE }), {
    refreshDeps: [current, menuId]
  })
  const data = useMemo(() => ({
    list: resp?.list || [],
    total: resp?.total || 0
  }), [resp])

  return (
    <Fragment>
      <ol className={styles.container}>
        {
          data.list.map(item => (
            <NewsItem key={item._id} data={item} code={code} />
          ))
        }
      </ol>
      <Pagination
        loading={loading}
        pageSize={PAGE_SIZE}
        current={current}
        total={data.total}
        onChange={p => setPage(p)}
      />
    </Fragment>
  )
}

export default NewsList