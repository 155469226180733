import React, { FunctionComponent, memo, useEffect } from 'react'
import styles from './Header.module.scss'
import cls from 'classnames'
import { useBoolean, useScroll } from 'ahooks'
import MobileHeader from '../MobileHeader'
import PCHeader from '../PCHeader'
import useBreakpoint from 'hooks/use-breakpoint'

/**
 * 检测滚动
 * @description 单独作为一个组件，是为了避免Header重复渲染
 */
const ScrollHelper: FunctionComponent<{ onChange: (isScrolled: boolean) => void }> = ({ onChange }) => {
  const { top } = useScroll(document)

  useEffect(() => {
    onChange(top > 20)
  }, [top, onChange])

  return null
}

/**
 * 顶部菜单栏组件
 * @description Logo、菜单栏等
 */
const Header: FunctionComponent = () => {
  const [isScrolled, { toggle: setScroll }] = useBoolean(false)
  const [forceScrolled, { toggle: setForceScroll }] = useBoolean(false)
  const rootStyle = cls(styles.container, (forceScrolled || isScrolled) && styles.container__scrolled)

  const isMobile = useBreakpoint()

  return (
    <header className={rootStyle}>
      <ScrollHelper onChange={setScroll} />
      {
        isMobile
          ? <MobileHeader onForceScroll={setForceScroll} /> :
          <PCHeader />
      }
    </header>
  )
}

export default memo(Header)