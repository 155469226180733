export enum ExhibitionType {
    common = 'common',
    special = 'special',
}

export type ExhibitionModel = {
    /** 仅开启后才作为展览处理 */
    is_exhibition?: boolean
    /** 展览类型 */
    exhibition_type?: ExhibitionType
    /** 展览开始时间 */
    exhibition_start_date?: number
    /** 展览结束时间 */
    exhibition_end_date?: number
    /** 展览地点 */
    exhibition_address?: string
}

export type ArticleModel = BaseModel & ExhibitionModel & {
    title: string
    cover?: string
    /** 封面宽度（适用于瀑布流计算） */
    cover_width?: number
    /** 封面高度（适用于瀑布流计算） */
    cover_height?: number
    /** 特殊的标识符 */
    code?: string
    /** 精确到毫秒的unix */
    publish_date?: number
    /** 关联的菜单标识符 */
    ref_menu: string
    /** 图文详情 */
    content?: string
    /** 外链文章 */
    external_url?: string
    /** 附件链接 */
    extraFile?: string
}

export type PageArticleReq = PageReq & {
    /** 所属菜单ID */
    menuId: string
}