import { createPersist } from 'api/helper'
import { getCloudBaseDB } from 'utils/cloudbase'
import { PlatformModel } from './types'

const DB = 'szsilk-platform'

/**
 * 获取所有相关平台信息
 * @description 用户本地缓存24小时
 */
export const getPlatforms = createPersist(async (): Promise<PlatformModel[]> => {
    const db = await getCloudBaseDB()
    const resp = await db
        .collection(DB)
        .where({})
        .get()

    if (resp.data) {
        return resp.data as PlatformModel[]
    } else {
        return []
    }
}, 'platforms', 24 * 60 * 60)