import React, { Fragment, FunctionComponent, memo, useMemo } from 'react'
import styles from './MasonryList.module.scss'
import { MenuPageProps } from '../types'
import useBreakpoint from 'hooks/use-breakpoint'
import { useCounter, useRequest } from 'ahooks'
import { pageArticle } from 'api/article/query'
import Pagination from 'components/Pagination'
import { ArticleModel } from 'api/article/types'
import AIOImage from 'react-aio-image'
import { useArticleHistory } from 'api/menu/helper'
import qs from 'query-string'
import { getTcbUrl } from 'utils/cloudbase'

type Props = MenuPageProps

const PAGE_SIZE = 20

const getCoverHeight = (columnWidth: number, item: ArticleModel) =>
  columnWidth * (item.cover_height || 1) / (item.cover_width || 1)

const MasonryItem = memo<{ data: ArticleModel, columnWidth: number } & Pick<Props, 'code'>>(({ code, data, columnWidth }) => {
  const { route } = useArticleHistory('?' + qs.stringify({ id: data._id }))

  return (
    <li key={data._id} className={styles.item} onClick={() => route(code, data)}>
      <AIOImage
        width='100%'
        src={getTcbUrl(data.cover)}
        className={styles.item_cover}
        height={getCoverHeight(columnWidth, data)}
      />
      <h3 className={styles.item_title}>{data.title}</h3>
    </li>
  )
})

/**
 * 菜单列表-瀑布流
 */
const MasonryList: FunctionComponent<Props> = ({ code, menuId }) => {
  const [current, { set: setPage }] = useCounter(1)
  const { data: resp, loading } = useRequest(async () => await pageArticle({ current, menuId, pageSize: PAGE_SIZE }), {
    refreshDeps: [current, menuId]
  })
  const { list, total } = useMemo(() => ({
    list: resp?.list || [],
    total: resp?.total || 0
  }), [resp])

  const isMobile = useBreakpoint()
  const columnGap = isMobile ? 14 : 30
  const columnWidth = isMobile ? ((document.body.clientWidth - 3 * 14) / 2) : ((1260 - 2 * 30) / 3)

  const columns = useMemo(() => {
    let result: ArticleModel[][] = []
    let uiHeights: number[] = []

    if (isMobile) {
      result = [[], []]
      uiHeights = [0, 0]
    } else {
      result = [[], [], []]
      uiHeights = [0, 0, 0]
    }

    list.forEach(item => {
      // 将item放入最小的纵列
      let minIndex = 0
      let minHeight = uiHeights[minIndex]
      const realHeight = columnWidth * (item.cover_height || 1) / (item.cover_width || 1) + columnGap

      for (var i = 1; i < uiHeights.length; i++) {
        const current = uiHeights[i]
        if (current < minHeight) {
          minIndex = i
          minHeight = current
        }
      }

      result[minIndex].push(item)
      uiHeights[minIndex] += realHeight
    })

    return result
  }, [isMobile, list, columnWidth, columnGap])

  return (
    <Fragment>
      <div className={styles.container}>
        {
          columns.map((column, i) => (
            <ul key={i} className={styles.column}>
              {
                column.map(item => (
                  <MasonryItem
                    key={item._id} 
                    code={code}
                    data={item}
                    columnWidth={columnWidth}
                  />
                ))
              }
            </ul>
          ))
        }
      </div>
      <Pagination
        loading={loading}
        pageSize={PAGE_SIZE}
        current={current}
        total={total}
        onChange={p => setPage(p)}
      />
    </Fragment>
  )
}

export default MasonryList