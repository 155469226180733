import React from 'react'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import routePath from './routePath'
// >>> import
import Article from 'pages/article'
import Menu from 'pages/menu'
import Index from 'pages/index'
import MainLayout from 'components/layouts/MainLayout'

const __ROUTE__ = [
	// >>> route
    { path: routePath['article'], comp: Article },
	{ path: routePath['menu'], comp: Menu },
	{ path: routePath.index, comp: Index },
]

/**
 * 整个APP的路由入口
 * 1. 展示类的静态站点路由结构很简单，只需要通过`yarn gen:page`，就能帮助自动注册路由相关配置
 * 2. 必须要有个首页
 * 3. 使用hash路由，适配腾讯云静态网站托管
 */
export default function App() {
	return (
		<HashRouter>
			<MainLayout>
				<Switch>
					{
						__ROUTE__.map(r => (
							<Route
								exact
								key={r.path}
								path={r.path}
								component={r.comp}
							/>
						))
					}
					<Route render={() => <Redirect to={routePath.index} />} />
				</Switch>
			</MainLayout>
		</HashRouter>

	)
}