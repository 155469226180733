export enum PlatformCode {
    beian = 'beian',
    wechat = 'wechat',
    weibo = 'weibo',
    weapp_edu = 'weapp-edu',
    address = 'address',
    open_time = 'open-time',
    home_video = 'home-video',
    home_list = 'home-list'
}

export type PlatformModel = BaseModel & {
    /** 名称 */
    title: string
    /** 标识符 */
    code: string
    /** 文字记录 */
    txt?: string
    /** 图片记录 */
    pic?: string
    /** 视频记录 */
    video?: string
    /** 菜单记录 */
    menu?: string
}